import toast from 'react-hot-toast'

// Services
import Parser from '../../services/Parser'
import Auth from '../../services/Auth'

export class ErrorHandlers {
  static TOAST = 'toast'
}

export const DefaultErrorStrategy = {
  400: (error) => {
    const msg = Parser.parseErrorResponse(error)
    toast.error(msg)
    return Promise.reject(msg)
  },
  401: (error) => {
    toast.error('Your session is no longer active. Redirecting to login.')

    setTimeout(() => {
      Auth.logout(true, 'invalid-token')
    }, 2000)

    return Promise.resolve()
  },
  default: (error) => {
    const msg = Parser.parseErrorResponse(error)
    toast.error(msg)
    return Promise.reject(msg)
  },
}

export const AiAssistantStrategy = {
  default: (error) => {
    return Promise.reject(error)
  },
}

export const LoginErrorStrategy = {
  default: (error) => {
    const msg = Parser.parseErrorResponse(error, false)
    return Promise.reject(msg)
  },
}

export const FormErrorStrategy = {
  400: (error) => {
    const msg = Parser.parseErrorResponse(error)

    return Promise.reject(msg)
  },
  default: (error) => {
    const msg = Parser.parseErrorResponse(error)

    return Promise.reject(msg)
  },
}

// defining a custom error handler for all APIs
export const globalErrorHandler = (error) => {
  const statusCode = error.response?.status

  if (error.code === 'ERR_CANCELED') {
    return Promise.resolve()
  }

  const errorStrategy = error.config.errorStrategy
    ? error.config.errorStrategy
    : DefaultErrorStrategy

  console.debug('inError')

  //Handle specific error code
  if (errorStrategy[statusCode]) {
    return errorStrategy[statusCode](error)
  }

  //No specific error code error handling to handle with catch-all
  if (errorStrategy['default']) {
    return errorStrategy.default(error)
  }

  return Promise.reject(error)
}
